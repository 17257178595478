import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { screenSizes } from '../../../lib/constants'

const Rectangle = styled.div`
  background-image: url('${({ imgObj }) => giveMeTheImage(imgObj)}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
    height: ${({ height }) => height};   
    position: relative;
  
  @media only screen and (max-width: ${screenSizes.sm})  {
    width: 100%;
    min-width: 100%;
  }
  
  @media only screen and (max-width: ${screenSizes.md})  {
    width: 100%;
    min-width: 200px;
  }
`

const RectangleTitle = styled.div`
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    color: #fff;
    text-decoration: none;
    font-size: 1.2em;
    font-weight: bold;
`

const RectangleMask = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
`

const ContentRectangle = props => {
    return (
        <Rectangle {...props}>
            <RectangleMask />
            <RectangleTitle>{props.children}</RectangleTitle>
        </Rectangle>
    )
}

const giveMeTheImage = obj => {
    if (obj) {
        return obj.url
    }

    return ContentRectangle.defaultProps.imgObj.file.url
}

ContentRectangle.propTypes = {
    imgObj: PropTypes.object,
    height: PropTypes.string
}

ContentRectangle.defaultProps = {
    imgObj: {
        file: {
            fileName: 'Default Name',
            url: 'http://via.placeholder.com/300x380',
        },
    },
    height: '440px'
}

export default ContentRectangle
