import styled from 'styled-components'
import PropTypes from 'prop-types'
import { generateResponsiveStyles } from '../../../lib/media'
import { css } from 'styled-components'

const baseStyles = ({ flexBasis, flexGrow, flexShrink }) => css`
    flex-basis: ${flexBasis};
    flex-grow: ${flexGrow};
    flex-shrink: ${flexShrink};
`

// define the styling function for the responsive props
const responsiveStyles = generateResponsiveStyles(baseStyles)

const Flex = styled.div`
  ${baseStyles} // the base styles
  ${responsiveStyles} // the responsive styles
`

Flex.propTypes = {
    flexBasis: PropTypes.string,
    flexGrow: PropTypes.number,
    flexShrink: PropTypes.number,
}

Flex.defaultProps = {
    flexBasis: '100%',
    flexGrow: 0,
    flexShrink: 1,
}

export default Flex
