import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import ContentRectangle from '../../uielements/ContentRectangle'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import InnerTitle from '../../uielements/InnerTitle'
import { useTranslation } from 'react-i18next'
import Flex from '../../uielements/Flex'
import Spacer from '../../uielements/Spacer'
import FlexWrapper from '../../uielements/flexWrapper'

const Subtitle = styled.h4`
    text-transform: uppercase;
    color: ${({ theme }) => theme.blue};
    font-weight: bold;
    font-size: 1.3em;
    line-height: 1.1em;
    max-width: 400px;
`

const MyBlogGrid = ({ data, props }) => {
    const { t, i18n } = useTranslation()

    return (
        <>
            <InnerTitle>{t('blog')}</InnerTitle>
            <Subtitle>{t('blog-desc')}</Subtitle>
            <Spacer top="4" />
            {/*<RichContent body={data.blog.totalCount > 0 ? data.blog.nodes[0].category[0].excerpt.raw : ""}/>*/}
            <FlexWrapper xl={{ margin: '0 -20px 0 -10px' }}>
                {data.blog.nodes.map(page => {
                    return (
                        page.node_locale === i18n.language && (
                            <Flex
                                flexBasis="33%"
                                key={page.id}
                                sm={{ flexBasis: '100%' }}
                                md={{ flexBasis: '50%' }}
                                lg={{ flexBasis: '50%' }}
                            >
                                <Spacer
                                    left="1"
                                    right="1"
                                    bottom="2"
                                    sm={{ right: '0', bottom: '2' }}
                                >
                                    <Link
                                        title={page.title}
                                        to={'/blog/' + page.slug}
                                    >
                                        <ContentRectangle
                                            imgObj={page.featuredImage}
                                        >
                                            {page.title}
                                        </ContentRectangle>
                                    </Link>
                                </Spacer>
                            </Flex>
                        )
                    )
                })}
            </FlexWrapper>
        </>
    )
}

const BlogGrid = props => {
    return (
        <StaticQuery
            query={graphql`
                query blogGrid {
                    blog: allContentfulBlog(
                        filter: { mainArticle: { eq: "yes" } }
                    ) {
                        nodes {
                            slug
                            title
                            id
                            node_locale
                            createdAt
                            featuredImage {
                                url
                                title
                                gatsbyImageData(
                                    quality: 75
                                    jpegProgressive: true
                                    sizes: "1920, 960, 480"
                                    cropFocus: CENTER
                                )
                            }
                        }
                    }
                }
            `}
            render={data => <MyBlogGrid data={data} props={props} />}
        ></StaticQuery>
    )
}

BlogGrid.defaultProps = {
    displayInHome: false,
}

BlogGrid.propTypes = {
    displayInHome: PropTypes.bool,
}

export default BlogGrid
