import React from 'react'
import Layout from '../components/layout'
import BlogGrid from '../components/sections/BlogGrid'
import Wrapper from '../components/Wrapper'

const BlogPage = () => (
    <Layout>
        <Wrapper>
            <BlogGrid />
        </Wrapper>
    </Layout>
)

export default BlogPage
